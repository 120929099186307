import Vue from 'vue'
import App from './App.vue'
import router from './router';
import axios from 'axios'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.prototype.$axios = axios
import dataV from '@jiaminghi/data-view';
Vue.config.productionTip = false
Vue.use(dataV);
Vue.use(Antd);
new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
