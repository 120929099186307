<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  onshow() {
    // Chrome IE 360
    window.addEventListener(
      "mousewheel",
      function (event) {
        if (event.ctrlKey === true || event.metaKey) {
          event.preventDefault();
        }
      },
      { passive: false }
    );

    //firefox
    window.addEventListener(
      "DOMMouseScroll",
      function (event) {
        if (event.ctrlKey === true || event.metaKey) {
          event.preventDefault();
        }
      },
      { passive: false }
    );
  },
};
</script>
<style scoped>
#app {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  overflow: hidden;
}
/* .ant-form-item-label > label {
    color: rgba(0, 0, 0, 0);
} */
::v-deep .dv-scroll-board {
  width: 99% !important;
}
::v-deep .header-item:nth-child(1),
::v-deep .ceil:nth-child(1) {
  width: 55% !important;
  text-align: center !important;
}
::v-deep .header-item:nth-child(2),
::v-deep .ceil:nth-child(2) {
  width: 25% !important;
  text-align: center !important;
}
::v-deep .header-item:nth-child(3),
::v-deep .ceil:nth-child(3) {
  width: 20% !important;
  text-align: center !important;
}

</style>
